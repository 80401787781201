export const CLOUDFRONT_URL = 'https://d1v18l4r9qkmgu.cloudfront.net/';

export const CHARTS_PER_PAGE = 32;

export const DIFF_COLORS: { [diff: string]: string[] } = {
  NOV: ['from-violet-400/25', 'hover:from-violet-400'],
  ADV: ['from-amber-400/25', 'hover:from-amber-400'],
  EXH: ['from-red-400/25', 'hover:from-red-400'],
  MXM: ['from-gray-400/25', 'hover:from-gray-400'],
  INF: ['from-fuchsia-400/25', 'hover:from-fuchsia-400'],
  GRV: ['from-orange-400/25', 'hover:from-orange-400'],
  HVN: ['from-sky-400/25', 'hover:from-sky-400'],
  VVD: ['from-pink-400/25', 'hover:from-pink-400'],
  XCD: ['from-blue-400/25', 'hover:from-blue-400'],
};

export const DIFF_COLORS_ACTIVE: { [diff: string]: string[] } = {
  NOV: [
    'bg-gradient-to-t from-violet-400',
    'bg-gradient-to-t from-violet-400/25 hover:from-violet-400',
  ],
  ADV: [
    'bg-gradient-to-t from-amber-400',
    'bg-gradient-to-t from-amber-400/25 hover:from-amber-400',
  ],
  EXH: [
    'bg-gradient-to-t from-red-400',
    'bg-gradient-to-t from-red-400/25 hover:from-red-400',
  ],
  MXM: [
    'bg-gradient-to-t from-gray-400',
    'bg-gradient-to-t from-gray-400/25 hover:from-gray-400',
  ],
  INF: [
    'bg-gradient-to-t from-fuchsia-400',
    'bg-gradient-to-t from-fuchsia-400/25 hover:from-fuchsia-400',
  ],
  GRV: [
    'bg-gradient-to-t from-orange-400',
    'bg-gradient-to-t from-orange-400/25 hover:from-orange-400',
  ],
  HVN: [
    'bg-gradient-to-t from-sky-400',
    'bg-gradient-to-t from-sky-400/25 hover:from-sky-400',
  ],
  VVD: [
    'bg-gradient-to-t from-pink-400',
    'bg-gradient-to-t from-pink-400/25 hover:from-pink-400',
  ],
  XCD: [
    'bg-gradient-to-t from-blue-400',
    'bg-gradient-to-t from-blue-400/25 hover:from-blue-400',
  ],
};

export const FILTER_OPTIONS = [
  { full: 'All', short: 'All' },
  { full: 'Level 16', short: 'Lv. 16' },
  { full: 'Level 17', short: 'Lv. 17' },
  { full: 'Level 18', short: 'Lv. 18' },
  { full: 'Level 19', short: 'Lv. 19' },
  { full: 'Level 20', short: 'Lv. 20' },
  { full: 'INFINITE', short: 'INF' },
  { full: 'GRAVITY', short: 'GRV' },
  { full: 'HEAVENLY', short: 'HVN' },
  { full: 'VIVID', short: 'VVD' },
];

export const FILTER_PARAM_NAMES = [
  'all',
  'lv16',
  'lv17',
  'lv18',
  'lv19',
  'lv20',
  'inf',
  'grv',
  'hvn',
  'vvd',
];

export const SORT_OPTIONS = [
  { name: 'Title (A-Z)', dir: 'asc' },
  { name: 'Title (Z-A)', dir: 'desc' },
  { name: 'Artist (A-Z)', dir: 'asc' },
  { name: 'Artist (Z-A)', dir: 'desc' },
  { name: 'Level (1-20)', dir: 'asc' },
  { name: 'Level (20-1)', dir: 'desc' },
  { name: 'Newest First', dir: 'asc' },
  { name: 'Oldest First', dir: 'desc' },
];

import { NavLink } from 'react-router-dom';
import { ChartCardProps } from '../types';
import { CLOUDFRONT_URL, DIFF_COLORS_ACTIVE } from '../constants';

function ChartCard(props: ChartCardProps) {
  const { chart, diff, diffIdx, isMirror, setDiff, setDiffIdx, homeUrl } =
    props;
  const bpm =
    chart.bpm[0] === chart.bpm[1] ? chart.bpm[0] : chart.bpm.join(' - ');

  return (
    <div className="flex flex-col absolute top-[72px] right-1/2 translate-x-2/4 w-80 pb-6 bg-gtgr-700 rounded-xl border-2 border-gtgr-500">
      <img
        alt="jacket"
        className="min-w-[316px] min-h-80 mb-6 rounded-tl-xl rounded-tr-xl"
        src={`${CLOUDFRONT_URL}${diff.jacketPath}`}
        // src={require(`../data/images/${diff.jacketPaths.large}`)}
      />
      <div className="flex flex-col gap-y-1 justify-center ml-6 mr-6 whitespace-normal overflow-hidden">
        <div className="text-lg text-gray-200 font-semibold">{chart.title}</div>
        <div className="text-md text-gray-400">{chart.artist}</div>
        <div className="flex flex-row">
          <p className="text-md text-gray-200 pr-1 grow-0">{bpm}</p>
          <p className="text-md text-gray-200 font-semibold"> BPM</p>
        </div>
        <div className="flex flex-row">
          <p className="text-md text-gray-200 font-semibold pr-1 whitespace-nowrap">
            Effected by
          </p>
          <div className="text-md text-gray-200 pr-1">{diff.effector}</div>
        </div>
        {diff.videoUrl !== '' && (
          <p>
            <a
              href={diff.videoUrl}
              target="_blank"
              rel="noreferrer"
              className="text-lg text-blue-400 hover:text-blue-300 font-semibold"
            >
              Video Link
            </a>
          </p>
        )}
        <div className="flex gap-x-6 mt-2 mb-2">
          {chart.diffs.map((diff) => (
            <NavLink
              className={`${diff.idx === diffIdx ? 'cursor-default' : ''}`}
              to={`/unzip/${chart.id}/${diff.idx}${isMirror ? '/m' : ''}`}
              key={`${chart.id}-${diff.idx}`}
              state={homeUrl}
            >
              <div
                key={`${chart.id}-${diff.idx}`}
                className={`flex flex-col justify-center w-16 h-16 rounded-md ${
                  diff.idx === diffIdx
                    ? DIFF_COLORS_ACTIVE[diff.name][0]
                    : DIFF_COLORS_ACTIVE[diff.name][1]
                } ${
                  diff.idx === diffIdx ? '' : 'opacity-40 hover:opacity-100'
                }`}
                onClick={() => {
                  setDiff(diff);
                  setDiffIdx(diff.idx);
                }}
              >
                <div>
                  <p className="text-lg text-center text-gray-200 font-semibold tracking-widest">
                    {diff.level.toString().padStart(2, '0')}
                  </p>
                </div>
                <div>
                  <p className="text-center text-xs text-gray-200 font-normal">
                    {diff.name}
                  </p>
                </div>
              </div>
            </NavLink>
          ))}
        </div>
        <img
          alt="radar"
          className="w-64 h-64 mx-auto"
          src={`${CLOUDFRONT_URL}${diff.radarPath}`}
          // src={require(`../data/images/${diff.radarPath}`)}
        />
      </div>
    </div>
  );
}

export default ChartCard;

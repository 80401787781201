import { useNavigate, Link } from 'react-router-dom';
import { FILTER_OPTIONS, FILTER_PARAM_NAMES } from '../constants';
import { FilterMenuProps } from '../types';

function FilterMenu(props: FilterMenuProps) {
  const { filterIdx, handleFilter, searchedFor } = props;
  const hideFilter = searchedFor !== '';
  const navigate = useNavigate();

  return (
    <>
      <button
        id="filterMenuBtn"
        data-dropdown-toggle="filter-dropdown"
        className={`inline-flex items-center ml-6 mr-6 px-5 py-2.5 text-gray-200 text-sm text-center font-medium bg-gtgr-700 hover:bg-gtgr-600 border border-gtgr-600 rounded-lg ${
          hideFilter ? 'hidden' : ''
        }`}
        type="button"
      >
        {(FILTER_OPTIONS[filterIdx] && FILTER_OPTIONS[filterIdx].short) ||
          'All'}
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
      <div
        id="filter-dropdown"
        className="hidden z-10 w-40 ml-8 bg-gtgr-700 rounded-lg border border-gtgr-500"
      >
        <ul className="py-2 text-gray-200 text-base font-medium">
          {FILTER_OPTIONS.map((item, i) => (
            <Link to={`/f=${FILTER_PARAM_NAMES[i]}`} key={item.full}>
              <li key={item.full}>
                <div
                  className={`block px-4 py-2 ${
                    i === filterIdx
                      ? 'bg-gtgr-800 hover:bg-gtgr-800'
                      : 'hover:bg-gtgr-600'
                  }`}
                  onClick={() => handleFilter(i)}
                >
                  {item.full}
                </div>
              </li>
            </Link>
          ))}
        </ul>
      </div>
      {hideFilter && (
        <button
          id="resetBtn"
          className="inline-flex items-center ml-6 mr-6 px-5 py-2.5 text-gray-200 text-sm text-center font-medium bg-gtgr-700 hover:bg-gtgr-600 rounded-lg border border-gtgr-600"
          onClick={(e) => {
            e.preventDefault();
            handleFilter(0, false);
            navigate('/f=all');
          }}
          type="button"
        >
          All Charts
        </button>
      )}
    </>
  );
}

export default FilterMenu;

import { ChartToList } from '../types';

import allCharts from './charts/all.json';
import level16Charts from './charts/level_16.json';
import level17Charts from './charts/level_17.json';
import level18Charts from './charts/level_18.json';
import level19Charts from './charts/level_19.json';
import level20Charts from './charts/level_20.json';
import infCharts from './charts/inf.json';
import grvCharts from './charts/grv.json';
import hvnCharts from './charts/hvn.json';
import vvdCharts from './charts/vvd.json';

export const CHART_DBS = [
  allCharts,
  level16Charts,
  level17Charts,
  level18Charts,
  level19Charts,
  level20Charts,
  infCharts,
  grvCharts,
  hvnCharts,
  vvdCharts,
] as unknown as ChartToList[][];

import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { CLOUDFRONT_URL } from '../constants';
import { ChartToView } from '../types';
import ChartCard from './ChartCard';
import ScrollContainer from 'react-indiana-drag-scroll';
import chartsByIdData from '../data/charts/by_id.json';

const chartsById = chartsByIdData as unknown as { [id: string]: ChartToView };

function ChartView() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = location.pathname.split('/unzip/')[1].split('/');
  const chart = chartsById[params[0]];
  const [diffIdx, setDiffIdx] = useState(parseInt(params[1]));
  const [diff, setDiff] = useState(
    chart && chart.diffs.find((diff) => diff.idx === diffIdx)
  );
  const [isMirror, setIsMirror] = useState(
    (params[2] && params[2] === 'm') || false
  );
  const [showChartInfo, setShowChartInfo] = useState(false);
  const homeUrl = (location.state as string) || '/';

  useEffect(() => {
    if (chart && diff) {
      document.title = `${chart.title} [${diff.name} ${diff.level}]${
        isMirror ? ' (Mirror)' : ''
      }`;
    } else {
      document.title = 'Not Found';

      setTimeout(() => {
        navigate('/');
      }, 1000);
    }
  });

  window.onpopstate = () => {
    window.location.reload();
  };

  function toggleMirror() {
    setIsMirror(!isMirror);
    navigate(`/unzip/${chart.id}/${diffIdx}/${isMirror ? '' : 'm'}`, {
      state: homeUrl,
    });
  }

  return (
    chart &&
    diff && (
      <div id="main-div" className="flex w-full pt-8 pl-6 pr-6">
        <nav className="fixed w-full z-20 top-0 start-0 h-20 bg-zinc-900">
          <div className="flex flex-row relative justify-between items-center h-20 max-w-[59rem] mx-auto">
            <a
              href={homeUrl}
              className="flex items-center justify-center w-32 h-10 ml-6 text-base text-gray-200 font-medium rounded-lg bg-gtgr-700 hover:bg-gtgr-600 border border-gtgr-600
              "
            >
              Home
            </a>
            <button
              className="flex items-center justify-center w-32 h-10 text-base text-gray-200 font-medium bg-gtgr-700 hover:bg-gtgr-600 rounded-lg border border-gtgr-600"
              onClick={() => {
                setShowChartInfo(!showChartInfo);
              }}
            >
              Chart Info
            </button>
            {showChartInfo && (
              <ChartCard
                chart={chart}
                diff={diff}
                diffIdx={diffIdx}
                isMirror={isMirror}
                setDiff={setDiff}
                setDiffIdx={setDiffIdx}
                homeUrl={homeUrl}
              />
            )}
            <button
              className={`flex items-center justify-center w-32 h-10 mr-6 text-base text-gray-200 font-medium rounded-lg border border-gtgr-600 ${
                isMirror
                  ? 'bg-blue-600 hover:bg-blue-500 border-blue-400'
                  : 'bg-gtgr-700 hover:bg-gtgr-600 border-gtgr-600'
              }`}
              onClick={toggleMirror}
            >
              {isMirror ? 'Mirror ON' : 'Mirror OFF'}
            </button>
          </div>
        </nav>
        {isMobile ? (
          <div
            className={`w-[96rem] h-[64rem] mt-16 mb-8 mx-auto hover:cursor-move`}
            style={{ scrollbarWidth: 'thin' }}
            onClick={(e) => {
              e.preventDefault();

              if (showChartInfo) {
                setShowChartInfo(false);
              }
            }}
          >
            <img
              alt="chart-render"
              className="max-w-none object-none"
              style={{ margin: '0 auto' }}
              src={`${CLOUDFRONT_URL}${
                isMirror ? diff.renderPaths.mirror : diff.renderPaths.normal
              }`}
              // src={require(`../data/images/${
              //   isMirror ? diff.renderPaths.mirror : diff.renderPaths.normal
              // }`)}
            />
          </div>
        ) : (
          <ScrollContainer
            className={`w-[96rem] h-[64rem] mt-16 mb-8 mx-auto overflow-x-auto hover:cursor-grab`}
            style={{ scrollbarWidth: 'thin' }}
            vertical={false}
            onClick={(e) => {
              e.preventDefault();

              if (showChartInfo) {
                setShowChartInfo(false);
              }
            }}
          >
            <img
              alt="chart-render"
              className="max-w-none object-none"
              style={{ margin: '0 auto' }}
              src={`${CLOUDFRONT_URL}${
                isMirror ? diff.renderPaths.mirror : diff.renderPaths.normal
              }`}
              // src={require(`../data/images/${
              //   isMirror ? diff.renderPaths.mirror : diff.renderPaths.normal
              // }`)}
            />
          </ScrollContainer>
        )}
      </div>
    )
  );
}

export default ChartView;

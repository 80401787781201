import Fuse from 'fuse.js';
import { ChartToList, InitialHomeState } from './types';
import { FILTER_PARAM_NAMES, SORT_OPTIONS } from './constants';
import allChartsDiffSeparated from './data/charts/all_diff_separated.json';

const fuse = new Fuse(allChartsDiffSeparated, {
  keys: ['title', 'artist', 'ascii', 'diffs.effector'],
  threshold: 0.24,
});

export function getHeaderText(searchedFor: string, numCharts: number) {
  if (searchedFor !== '') {
    return `Results for "${searchedFor}" (${numCharts})`;
  }

  return `${numCharts} charts`;
}

export function getInitialState(
  path: string,
  CHART_DBS: ChartToList[][]
): InitialHomeState {
  const filterIdx = path.startsWith('/f=')
    ? FILTER_PARAM_NAMES.indexOf(path.split('/f=')[1])
    : 0;
  const searchParam = path.startsWith('/search=')
    ? decodeURIComponent(path.split('/search=')[1])
    : '';

  if (filterIdx !== 0) {
    return {
      charts: CHART_DBS[filterIdx],
      filterIdx,
      searchedFor: '',
    };
  }

  if (searchParam === '') {
    return {
      charts: CHART_DBS[0],
      filterIdx: 0,
      searchedFor: '',
    };
  }

  return {
    charts: getSearchResults(searchParam),
    filterIdx: 0,
    searchedFor: searchParam,
  };
}

export function getSortedCharts(
  charts: ChartToList[],
  i: number,
  filterIdx: number
): ChartToList[] {
  const { name, dir } = SORT_OPTIONS[i];

  // These charts are already filtered by level so there's no need to sort them
  if (name.includes('Level') && filterIdx >= 1 && filterIdx <= 5) {
    return charts;
  }

  const sorted = [...charts].sort((a, b) => {
    if (name.includes('Title')) {
      return dir === 'asc'
        ? a.title.localeCompare(b.title)
        : b.title.localeCompare(a.title);
    } else if (name.includes('Artist')) {
      return dir === 'asc'
        ? a.artist.localeCompare(b.artist)
        : b.artist.localeCompare(a.artist);
    } else if (name.includes('Level')) {
      const la = a.diffs[0].level;
      const lb = b.diffs[0].level;

      return dir === 'asc' ? la - lb : lb - la;
    }

    return dir === 'asc'
      ? b.dateAdded - a.dateAdded
      : a.dateAdded - b.dateAdded;
  });

  return sorted;
}

export function getSearchResults(searchInput: string): ChartToList[] {
  return fuse.search(searchInput).map((result) => result.item);
}

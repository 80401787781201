import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CHART_DBS } from '../data/index';
import { CHARTS_PER_PAGE, FILTER_PARAM_NAMES } from '../constants';
import {
  getHeaderText,
  getInitialState,
  getSearchResults,
  getSortedCharts,
} from '../helpers';
import { ChartToList } from '../types';
import InfiniteScroll from 'react-infinite-scroll-component';
import ChartList from './ChartList';
import FilterMenu from './FilterMenu';
import Searchbar from './Searchbar';

function Home() {
  const location = useLocation();
  const navigate = useNavigate();
  const initState = getInitialState(location.pathname, CHART_DBS);

  const [filterIdx, setFilterIdx] = useState(initState.filterIdx);
  const [sortIdx, setSortIdx] = useState(0);
  const [charts, setCharts] = useState(initState.charts);
  const [headerText, setHeaderText] = useState(
    getHeaderText(initState.searchedFor, charts.length)
  );
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const [searchedFor, setSearchedFor] = useState(initState.searchedFor);
  const [homeUrl, setHomeUrl] = useState(location.pathname);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  }, [charts]);

  function sortCharts(chartsToSort: ChartToList[], i: number, j: number) {
    const sorted = getSortedCharts(chartsToSort, i, j);

    setCharts(sorted);
    setSortIdx(i);
  }

  window.onpopstate = () => {
    // Re-render the page when the user navigates back/forwards
    // To make sure we're always parsing URL for params
    window.location.reload();
  };

  function handleFilter(i: number, closeDropdown: boolean = true) {
    sortCharts(CHART_DBS[i], sortIdx, i);
    setFilterIdx(i);
    setPage(1);
    setSearchInput('');
    setSearchedFor('');
    setHeaderText(getHeaderText(searchInput, CHART_DBS[i].length));
    setHomeUrl(`/f=${FILTER_PARAM_NAMES[i]}`);

    if (closeDropdown) {
      document.getElementById('filterMenuBtn')?.click();
    }
  }

  function handleSort(i: number) {
    sortCharts(charts, i, filterIdx);
    document.getElementById('sortMenuBtn')?.click();
  }

  function handleChartSearch() {
    const searchResults = getSearchResults(searchInput);

    sortCharts(searchResults, sortIdx, 0);
    setFilterIdx(0);
    setPage(1);
    setSearchInput('');
    setSearchedFor(searchInput);
    setHeaderText(getHeaderText(searchInput, searchResults.length));
    setHomeUrl(`/search=${encodeURIComponent(searchInput)}`);
    navigate(`/search=${encodeURIComponent(searchInput)}`);
  }

  function loadMore() {
    setTimeout(() => {
      setPage(page + 1);
    }, 500);
  }

  return (
    <div className="mr-6 ml-6">
      <nav className="fixed w-full z-30 top-0 start-0 h-20 bg-zinc-900 shadow-lg">
        <div className="flex flex-row justify-between items-center h-20 max-w-[59rem] mx-auto">
          <FilterMenu
            filterIdx={filterIdx}
            handleFilter={handleFilter}
            searchedFor={searchedFor}
          />
          <Searchbar
            handleSearch={handleChartSearch}
            setSearchInput={setSearchInput}
            searchInput={searchInput}
          />
        </div>
      </nav>
      <InfiniteScroll
        dataLength={CHARTS_PER_PAGE * page}
        hasMore={CHARTS_PER_PAGE * page < charts.length}
        next={loadMore}
        loader={<></>}
      >
        <div className="flex flex-col justify-center max-w-[56rem] pt-6 mt-20 mx-auto">
          <ChartList
            charts={charts}
            handleSort={handleSort}
            headerText={headerText}
            homeUrl={homeUrl}
            pageNum={page}
            sortIdx={sortIdx}
          />
        </div>
      </InfiniteScroll>
    </div>
  );
}

export default Home;

interface SearchbarProps {
  handleSearch: Function;
  setSearchInput: Function;
  searchInput: string;
}

function Searchbar(props: SearchbarProps) {
  const { handleSearch, setSearchInput, searchInput } = props;

  return (
    <div className="max-w-64 mr-6">
      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg
            className="w-4 h-4 text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </svg>
        </div>
        <input
          className="block w-full p-2 ps-10 text-base text-gtgr-800 rounded-lg bg-gray-200 placeholder-gray-400 focus:outline-none border border-gtgr-500"
          placeholder="Search charts..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearch(true);
            }
          }}
        />
      </div>
    </div>
  );
}

export default Searchbar;

import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ChartToList } from '../types';
import { CLOUDFRONT_URL, DIFF_COLORS } from '../constants';

function diffColor(diff: string) {
  return `bg-gradient-to-t ${DIFF_COLORS[diff][0]} ${DIFF_COLORS[diff][1]}`;
}

function getStyle(i: number, numDiffs: number) {
  if (numDiffs === 1) {
    return 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none sm:rounded-br-lg sm:rounded-tr-lg sm:border-l border-gtgr-500';
  }

  if (i === 0) {
    return 'rounded-bl-lg sm:rounded-bl-none sm:rounded-tr-none sm:border-l border-gtgr-500';
  } else if (i === numDiffs - 1) {
    return 'rounded-br-lg sm:rounded-tr-lg sm:rounded-br-lg border-l border-gtgr-500';
  } else {
    return 'rounded-none border-l border-gtgr-500';
  }
}

function ChartListCard({
  chart,
  homeUrl,
}: {
  chart: ChartToList;
  homeUrl: string;
}) {
  const numDiffs = chart.diffs.length;
  const [effector, setEffector] = useState(chart.diffs[0].effector);
  const [showEffector, setShowEffector] = useState(numDiffs === 1);

  function handleHover(eff: string) {
    if (numDiffs > 1) {
      setEffector(eff);
      setShowEffector(true);
    }
  }

  return (
    <div className="flex flex-col sm:flex-row sm:relative text-gray-200 min-w-80 w-full mb-4 h-32 sm:h-20 rounded-lg bg-gtgr-700 border border-gtgr-500">
      <div className="flex flex-row mr-3">
        <img
          alt="jacket"
          className="w-20 h-20 sm:h-[78px] mr-3 rounded-tl-lg sm:rounded-bl-lg object-none border-r border-gtgr-500"
          src={`${CLOUDFRONT_URL}${chart.diffs[0].jacketPath}`}
          // src={require(`../data/images/${charts.diffs[0].jacketPath}`)}
        />
        <div className="flex flex-col overflow-x-scroll scrollbar-hide text-nowrap">
          <div className="flex flex-col justify-center h-20">
            <p className="text-sm sm:text-base font-normal text-gray-200">
              {chart.title}
            </p>
            <p
              className={`text-[0.6875rem] sm:text-xs text-gray-400 ${
                showEffector ? 'pb-0.5 sm:pb-1' : ''
              }`}
            >
              {chart.artist}
            </p>
            {showEffector && (
              <div className="flex text-xs sm:text-sm">
                <p className="font-medium pr-1 text-nowrap">Effected by</p>
                <p className="text-clip overflow-hidden">{effector}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center grow sm:grow-0 sm:ml-auto">
        {chart.diffs.map((diff, i) => {
          const style = getStyle(i, numDiffs);

          return (
            <NavLink
              to={`/unzip/${chart.id}/${diff.idx}`}
              key={`${chart.id}-${diff.idx}`}
              state={homeUrl}
              className="w-full border-t border-gtgr-500 sm:border-t-0"
              target="_blank"
            >
              <div
                key={`${chart.id}-${diff}`}
                className={`flex flex-row-reverse sm:flex-col text-center items-center justify-center sm:w-20 h-[46px] sm:h-20 pt-4 pb-4 ${diffColor(
                  diff.name
                )} ${style}`}
                onMouseOver={() => handleHover(diff.effector)}
                onMouseOut={() => {
                  if (numDiffs > 1) setShowEffector(false);
                }}
              >
                <div>
                  <p className="text-md sm:text-lg font-semibold tracking-widest">
                    {diff.level.toString().padStart(2, '0')}
                  </p>
                </div>
                <div>
                  <p className="mr-2 sm:mr-0 text-md sm:text-xs font-normal">
                    {diff.name}
                  </p>
                </div>
              </div>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
}

export default ChartListCard;

import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import ChartView from './ChartView';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:filter" element={<Home />} />
      <Route path="/search/:searchInput" element={<Home />} />
      <Route path="/unzip/:chartId/:diffIdx" element={<ChartView />} />
      <Route path="/unzip/:chartId/:diffIdx/m" element={<ChartView />} />
    </Routes>
  );
}

export default App;

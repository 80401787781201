import { CHARTS_PER_PAGE, SORT_OPTIONS } from '../constants';
import { ChartListProps } from '../types';
import ChartListCard from './ChartListCard';

function ChartList(props: ChartListProps) {
  const { charts, handleSort, headerText, pageNum, sortIdx, homeUrl } = props;

  return (
    <>
      <div className="flex flex-row justify-between items-center mb-6">
        <p className="text-2xl text-gray-200 truncate">{headerText}</p>
        <button
          id="sortMenuBtn"
          data-dropdown-toggle="sort-dropdown"
          className="inline-flex items-center h-10 px-5 py-2.5 text-gray-200 text-sm text-center font-medium bg-gtgr-700 hover:bg-gtgr-600 rounded-lg border border-gtgr-500"
          type="button"
        >
          {SORT_OPTIONS[sortIdx].name}
          <svg
            className="w-2.5 h-2.5 ms-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <div
          id="sort-dropdown"
          className="hidden select-none cursor-pointer z-30 w-40 ml-8 bg-gtgr-700 rounded-lg border border-gtgr-500"
        >
          <ul className="py-2 text-gray-200 text-base font-medium">
            {SORT_OPTIONS.map((item, i) => (
              <li key={`sort-item-${i}`}>
                <div
                  className={`block px-4 py-2 ${
                    i === sortIdx
                      ? 'bg-gtgr-800 hover:bg-gtgr-800'
                      : 'hover:bg-gtgr-600'
                  }`}
                  onClick={() => handleSort(i)}
                >
                  {item.name}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {charts.map(
        (chart, i) =>
          i < pageNum * CHARTS_PER_PAGE && (
            <ChartListCard
              key={`${chart.id}-${i}`}
              chart={chart}
              homeUrl={homeUrl}
            />
          )
      )}
    </>
  );
}

export default ChartList;
